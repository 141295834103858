.image-slider-section {
  user-select: none;

  &.is-loading {
    position: relative;

    .image-slider-wrapper {
      opacity: 0.2;
      pointer-events: none;
      filter: blur(6px);
    }
  }

  @media (max-width: 600px){
    margin-right: -20px;
  }


  .image-slider-wrapper {
    position: relative;

    .image-slider-next, .image-slider-prev {
      width: 40px;
      height: 40px;
      padding: 4px;
      background-color: var(--ow-red);
      color: var(--white);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 10;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: opacity 300ms ease;

      @media (max-width: 600px){
        display: none;
      }

      &:hover {
        background-color: var(--ow-red-hover);
      }

      &::after {
        display: none;
      }

      &:disabled {
        opacity: 0;
        pointer-events: none;
        cursor: auto;
      }
    }

    &:hover {
      .image-slider-next, .image-slider-prev {
        opacity: 1;

        &:disabled {
          opacity: 0.4;
        }
      }
    }

    .image-slider-next {
      right: -20px;
      transform: rotate(180deg)
    }

    .image-slider-prev {
      left: -20px;
    }
  }

  .images-list {
    padding: 24px 0;
    align-items: center;

    li {
      width: 440px;
      margin-right: 24px;

      @media (max-width: 800px){
        width: 48.3%;
      }

      @media (max-width: 600px){
        width: 95%;
        margin-right: 12px;
      }

      img {
        border: 2px solid var(--border-tertiary);
        transition: all 300ms ease;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 0px 29px 1px rgba(255, 0, 0, 0.18);
        }
      }


      .video-thumb-container {

        > img {
          transition: opacity 300ms ease;
          opacity: 1;
          position: relative;
          z-index: 1;
          width: 440px;
        }

        &:hover {
          > img {
            opacity: 0;

            @media (max-width: 600px){
              opacity: 1;
            }
          }
        }

        .yt-video {
          border: 2px solid var(--border-tertiary);
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          z-index: 0;
        }

        .play-btn {
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: var(--white);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 24px;
          letter-spacing: 0.04em;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          padding: 8px 12px;
          width: max-content;
          height: max-content;
          z-index: 2;

          &:hover {
            transform: scale(0.8);
          }

          svg {
            width: 13px;
            height: 12px;
            position: relative;
            bottom: -1px;
          }
        }
      }
    }
  }

  .image-slider-pagination {
    display: flex;
    gap: 24px;
    width: fit-content;
    margin: 0 auto 32px;
    align-items: center;
    padding-right: 20px;

    &:empty {
      display: none;
    }

    span {
      background-color: var(--text-quaternary);
      border: 1px solid transparent;
      opacity: 1;
      width: 8px;
      height: 8px;
      border-radius: 50%;

      &.swiper-pagination-bullet-active {
        background-color: transparent;
        border: 1px solid var(--white);
        width: 10px;
        height: 10px;
      }
    }
  }

}
