.page-header {
  max-width: 1436px;
  margin: auto;
  padding: 0 20px;

  h1 {
    font-size: 44px;
    line-height: 56px;
    margin-bottom: 48px;

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }

}
