.reviews-section {
  margin: 40px 0;
  position: relative;

  @media (max-width: 600px) {
    margin: 24px 0;
  }

  &.is-loading {
    .ratings-dashboard,
    .reviews-slider-section {
      opacity: 0.2;
      pointer-events: none;
      filter: blur(6px);
    }
  }

  > h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 116.667%;
    margin-bottom: 24px;

    @media (max-width: 600px) {
      margin-bottom: 16px;
      font-size: 20px;
    }
  }
}
