main {
  @media (max-width: 800px) {
    &:has(.app-page-layout .remind-me-window-section) {
      + footer {
        padding-bottom: 72px;
      }
    }
  }
}

.app-page-layout {
  margin-bottom: 56px;

  &:not(.light-template) {
    .app-page-sections {
      max-width: 1152px;
      padding: 0 20px;
      margin: auto;
      position: relative;
      margin-top: -320px;
      transition: all 150ms ease;
  
      @media (max-width: 1440px) {
        margin-top: -270px;
      }
  
      @media (max-width: 1240px) {
        max-width: 100%;
      }
  
      @media (max-width: 600px) {
        margin-top: -250px;
      }
    }
  
    .discovery-sections {
      background-color: var(--surface-primary);
      padding: 56px 20px;
      width: 100%;

      &:empty {
        display: none;
      }

      //hide till all of the store is ready for mobile
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &.light-template {

    .app-page-sections {
      max-width: 1152px;
      padding: 80px 20px 0;
      margin: auto;
      position: relative;
      transition: all 150ms ease;
  
      @media (max-width: 800px) {
        padding: 20px 20px 0;
      }
  
    }
  }

}
