header {
  height: 80px;
  padding: 0 20px;
  background-color: var(--black);
  position: sticky;
  top: 0;
  z-index: 100;

  .header-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 48px;
    align-items: center;
    height: 100%;

    @media (max-width: 1300px) {
      gap: 16px;
    }

    .logo-area {
      display: flex;

      svg {
        max-width: 287px;
        height: 50px;
        width: 100%;
        position: relative;
        bottom: 1px;

        @media (max-width: 1300px) {
          max-width: 230px;
        }
      }
    }

    .right-side {
      display: flex;
      gap: 16px;
      background: var(--black);

      @media (max-width: 1024px) {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        padding: 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms ease;
      }

      .dl-btn {
        @media (max-width: 1024px) {
          display: none;
        }
      }

    }

    .left-side {
      display: flex;
      align-items: center;
      gap: 80px;

      @media (max-width: 1300px) {
        gap: 12px;
        flex: 1;
      }
    }

    &.is-open {
      .navbar, .right-side {
        opacity: 1;
        pointer-events: all;
      }

      .navbar .menu-list .menu-item .sub-menu {
        pointer-events: all;
      }
    }
  }

  @import '../common/hamburger-menu-button';
}

body:has(.header-inner.is-open) {
  overflow: hidden;
}
