.ratings-dashboard {
  margin: 24px 0;
  background-color: var(--surface-secondary);
  padding: 24px 40px;

  @media (max-width: 600px) {
    padding: 24px 32px;
    margin: 16px 0;
  }

  .dashboard-view {
    max-width: 710px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    h3 {
      font-weight: 900;
      font-size: 96px;
      line-height: 104px;
      height: 104px;
      display: flex;
      align-items: baseline;
      color: var(--white);

      @media (max-width: 600px) {
        font-size: 64px;
        height: 68px;
        line-height: 62px;
      }

      span {
        font-weight: 700;
      }

      .best-rating {
        font-family: var(--font-montserrat);
        font-weight: 700;
        font-size: 14px;
        line-height: 0px;
        position: relative;
        top: 0px;
        color: var(--text-quaternary);

        // @media (max-width: 600px) {
        //   top: -12px;
        // }
      }
    }

    h4 {
      font-family: var(--font-montserrat);
      text-align: center;
      color: var(--text-quaternary);
      font-weight: 500;
      font-size: 14px;
    }

    .rating-stats {
      display: flex;
      flex-direction: row-reverse;
      padding: 12px 0;
      gap: 32px;

      @media (max-width: 600px) {
        flex-direction: column-reverse;
        width: 100%;
        gap: 8px;
        padding: 0;
      }
    }

    .clean-btn {
      position: absolute;
      top: 10px;
      right: 0;

      @media (max-width: 600px) {
        top: 3px;
      }
    }
  }
}
