 .hamburger-menu-button {
  z-index: 1;
  display: block;
  background: transparent;
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  cursor: pointer;

  @media (min-width: 1300px) {
    display: none;
  }

  > div {
      position: relative;
      width: 24px;
      height: 20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      transition: .4s ease-in-out;

      span {
          background: var(--text-secondary);
          display: block;
          width: 100%;
          height: 2px;
          position: absolute;
          transition: .4s ease-in-out;
          border-radius: 1px;

          &:nth-child(1) {
              top: 0;
              left: 0;
          }

          &:nth-child(2) {
              top: 8px;
              left: 0;
          }

          &:nth-child(3) {
              top: 16px;
              left: 0;
          }
      }
  }

  &.is-open {
      > div {
          transform: rotate(-180deg);

          span {
            background: var(--text-secondary);
              &:nth-child(1) {
                  transform: translate(0px, 8px) rotate(45deg);
              }

              &:nth-child(2) {
                  transform: scale(0, 1);
              }

              &:nth-child(3) {
                  transform: translate(0px, -8px) rotate(-45deg);
              }
          }
      }
  }
}

