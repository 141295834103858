:root {
  --star-color: var(--surface-tertiary);
  --star-background: var(--ow-red);
}

.star-rating {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: 18px;
  font-family: Times; // make sure ★ appears correctly
  line-height: 0;

  &.star-color-2 { 
    --star-color: var(--surface-quaternary);
  }

  &::before {
    content: "★★★★★";
    letter-spacing: 0px;
    background: linear-gradient(
      90deg,
      var(--star-background) var(--percent),
      var(--star-color) var(--percent)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 1024px) {
      letter-spacing: 1px;
    }
  }

}
