@import './common/copy-url';

.app-stats-section {
  margin: 24px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 800px) {
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
  }

  .app-stats-list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    @media (max-width: 600px) {
      gap: 8px;
      width: 100%;
    }

    .stats-item {
      position: relative;
      display: flex;
      gap: 8px 16px;

      @media (max-width: 600px) {
        width: 100%;
        flex-direction: column;
        border-bottom: 1px solid var(--surface-tertiary);
        padding-bottom: 8px;

        &:has(:nth-child(2)) {
          .stats-box:not(:last-of-type) {
            border-bottom: 1px solid var(--surface-tertiary);
            padding-bottom: 8px;
          }
        }
      }

      &:empty {
        display: none;
      }

      &:not(:last-child)::after {
        content: "";
        background-color: var(--surface-tertiary);
        width: 1px;
        height: 100%;
        position: absolute;
        right: -21px;
        top: 0;
        border-radius: 50%;

        @media (max-width: 600px) {
          display: none;
        }
      }
    }

    .stats-box {
      @media (max-width: 600px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      h2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: var(--text-quaternary);
        margin-bottom: 12px;

        @media (max-width: 600px) {
          margin-bottom: 0;
        }
      }

      .stats-output {
        font-family: var(--font-montserrat);
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: var(--white);
        display: flex;
        align-items: center;
        gap: 2px;
        position: relative;
        bottom: -2px;

        svg {
          width: 20px;
          height: 20px;
          color: var(--ow-red);
        }

        .star-rating {
          display: none;
          margin-right: 8px;
          margin-bottom: -2px;

          @media (max-width: 600px) {
            display: block;

            + svg {
              display: none;
            }
          }
        }
      }

      .stats-box-list {
        display: flex;
        align-items: center;
        gap: 12px;
        

        &.sg-list .stats-box-item img:hover {
          filter: drop-shadow(0px 0px 8px rgba(122, 122, 122, 0.54));
        }

        &.client-list .stats-box-item img:hover {
          filter: drop-shadow(0px 2px 7px rgba(255, 37, 37, 0.8));
        }

        .stats-box-item {
          a {
            display: block;
            width: 24px;
          }

          img {
            transition: all 300ms ease;
            width: 24px;
          }
        }
      }

      .supported-games-wrapper {
        display: flex;
        gap: 8px;
        height: 24px;

        .stats-box-list {
          &.sg-list {
            @media (max-width: 600px) {
              width: 132px;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}
