.full-screen-image-slider-section {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(18, 18, 18, 0.8);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  user-select: none;
  z-index: 100;
  opacity: 1;
  visibility: visible;
  transition: all 300ms ease;

  &:not(.modal-is-open) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .full-screen-image-slider-wrapper {
    .full-screen-image-slider {
      @media (max-width: 600px) {
        padding-bottom: 48px;
      }
    }

    .slider-actions {
      position: fixed;
      width: calc(100vw - 17px);
      height: 0;
      z-index: 2;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      @media (max-width: 600px) {
        width: 100vw;
        position: absolute;
        top: unset;
        bottom: 20px;
      }

      .full-screen-image-slider-next,
      .full-screen-image-slider-prev {
        width: 48px;
        height: 48px;
        padding: 4px;
        background-color: var(--ow-red);
        color: var(--white);
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 10;
        display: flex;
        align-items: center;

        @media (max-width: 960px) {
          width: 32px;
          height: 32px;
          background-color: transparent;
          color: var(--text-secondary);
          border: 1px solid var(--text-secondary);
        }

        &:hover {
          @media (min-width: 960px) {
            background-color: var(--ow-red-hover);
          }
        }

        &::after {
          display: none;
        }

        &:disabled {
          opacity: 0.4;
          pointer-events: none;
          cursor: auto;
        }
      }

      .full-screen-image-slider-next {
        right: 12.5%;
        transform: rotate(180deg);

        @media (max-width: 960px) {
          right: 10.5%;
        }

        @media (max-width: 600px) {
          right: 20px;
        }
      }

      .full-screen-image-slider-prev {
        left: 12.5%;

        @media (max-width: 960px) {
          left: 10.5%;
        }

        @media (max-width: 600px) {
          left: 20px;
          right: unset;
        }
      }
    }

    .full-view-actions {
      display: flex;
      justify-content: flex-end;
      position: fixed;
      z-index: 2;
      right: 24px;
      top: 24px;

      @media (max-width: 960px) {
        width: 32px;
        right: 16px;
        top: 16px;
      }

      button {
        @media (max-width: 960px) {
          width: 32px;
          height: 32px;
          padding-bottom: 2px;
          font-size: 0;
          gap: 0;
          justify-content: center;
        }
      }
    }
  }

  .full-screen-images-list {
    padding: 44px 0;
    align-items: center;

    @media (max-width: 960px) {
      padding: 0;
    }

    li {
      width: calc(100vw - 35%);
      margin: 0 64px;
      transition: all 300ms ease;

      @media (max-height: 900px) {
        width: calc(100vw - 45%);
      }

      @media (max-height: 750px) {
        width: calc(100vw - 40%);
      }

      @media (max-width: 600px) {
        width: calc(100vw - 40px);
        margin: 0 20px;
      }

      &:not(.swiper-slide-active) {
        opacity: 0.4;
      }

      &:focus-within {
        opacity: 1;
      }

      img {
        border: 2px solid var(--border-tertiary);
        width: 100%;
      }

      .slider-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        border: 2px solid var(--border-tertiary);
      }
    }
  }
}
