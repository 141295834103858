*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: normal;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

:target {
  scroll-margin-top: 200px;

  @media (max-width: 800px) {
    scroll-margin-top: 78px;
  }
}

body {
  background-color: var(--black);
  color: var(--text-secondary);
  font-family: var(--font-lato);
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  margin: 0;
  padding: 0;

  &.modal-is-open {
    height: 100vh;
    overflow: hidden;
    padding-right: 15px
  }
}

a {
  color: inherit;
  color: var(--text-secondary);
  text-decoration: none;
  text-underline-offset: 3px;
  overflow-wrap: break-word;
  transition: all 150ms ease;

  &:hover,
  &:focus {
    color: var(--ow-red-hover);
  }
}

p a {
  text-decoration: underline;
}

b,
strong {
  font-weight: bold;
}

a,
button {
  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px var(--ow-red);
  }

  &.btn-primary,
  &.expand-btn {
    &:focus-visible {
      box-shadow: inset 0 0 0 2px white;
    }
  }
}

hr {
  margin: 24px 0;
  border-color: var(--text-secondary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-montserrat);
  font-weight: 700;
  line-height: 127.273%;
}

h1, h2 {
  color: var(--white);
}

ul {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
}

svg {
  height: inherit;
  width: inherit;
}

fieldset {
  border: none;
}

.hide,
.hidden {
  display: none !important ;
}

label {
  display: inline-block;
}

input,
textarea {
  border-radius: 0;
  appearance: none;
  font-family: var(--font-lato);
}

.hidden {
  display: none !important;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

div,
ul {
  /* scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;

    @media (max-width: 600px) {
      width: 4px;
      height: 4px;
    }
  }
  &::-webkit-scrollbar-thumb {
    border-width: 0;
    background-color: var(--surface-tertiary);
    background-clip: padding-box;

    &:hover {
      background-color: var(--text-quaternary);
    }
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
}
