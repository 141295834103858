// loading the font Lato using next/fonts/google creating weird visual bug in capitalized letters...

@font-face {
  font-family: "Lato", sans-serif;
  src: url("/static_next/fonts/LatoLatin-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato", sans-serif;;
  src: url("/static_next/fonts/LatoLatin-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato", sans-serif;;
  src: url("/static_next/fonts/LatoLatin-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
