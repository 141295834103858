.app-header-section {
  margin-bottom: 16px;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }

  .app-header-content {
   max-height: 72px;

    @media (max-width: 800px) {
      order: -2;
    }

    @media (max-width: 600px) {
      max-height: unset;
    }

    .app-info-sticky-header {
      width: 100%;
      padding: 0;

      transition: all 150ms ease;

      &.floats {
        background-color: var(--surface-tertiary);
        height: 92px;
        padding: 20px 0;
        position: fixed;
        top: 78px;
        left: 0;
        z-index: 99;

        .app-info-sticky-header-inner {
          padding: 0 16px;

          .app-info {
            .app-thumb {
              width: 56px;
            }
          }

          .app-title {
            gap: 4px;
            h1 {
              font-size: 24px;
              line-height: 116.667%;
            }
          }

        }
      }

      .app-info-sticky-header-inner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px;
        max-width: 1168px;
        margin: auto;

        @media (max-width: 800px) {
          gap: 16px;
        }
      }
    }

    .app-info {
      display: flex;
      gap: 24px;

      .app-thumb {
        width: 56px;
        transition: width 150ms ease;

        @media (max-width: 600px) {
          flex: none;
        }
      }
    }

    .app-title {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @media (max-width: 600px) {
        gap: 4px;
        justify-content: center;
      }
      
      * {
        transition: all 150ms ease;
      }

      h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 125%;

        @media (max-width: 600px) {
          font-size: 24px;
          line-height: 116.667%;
        }
      }

      span {
        font-family: var(--font-montserrat);
        font-size: 14px;
        line-height: 140%;
        font-weight: 700;

        span {
          font-weight: 500;
          color: var(--text-quaternary);
        }
      }
    }
  }

  .app-description {
    margin-top: 24px;

    @media (max-width: 800px) {
      margin-top: 8px;
    }

    p {
      font-size: 18px;
      line-height: 155.556%;
      max-width: 564px;
      color: var(--text-tertiary);

      @media (max-width: 600px) {
        font-size: 16px;
        line-height: 140%;
      }

      a {
        display: inline-flex;
        align-items: center;
        gap: 8px;

        &:hover {
          color: var(--ow-red);
        }

        svg {
          width: 16px;
          height: 16px;
          position: relative;
          bottom: -2px;
          color: var(--icons-secondary);
        }
      }
    }

    @media (max-width: 800px) {
      order: -2;
    }
  }

  .app-download-area {

    * {
      transition: all 150ms ease;
    }

    a {
      min-width: 261px;
      text-align: center;
    }

    .download-actions {
      @media (max-width: 1024px) {
        display: none;
      }
    }

    .app-inventory-list {
      font-family: var(--font-montserrat);
      display: flex;
      justify-content: center;
      column-gap: 10px;
      row-gap: 8px;
      padding: 8px 0 0;
      font-weight: 500;
      font-size: 12px;
      font-style: normal;
      line-height: 133.333%;
      white-space: nowrap;

      @media (max-width: 800px) {
        padding: 0;
      }

      .app-inventory-item {
        position: relative;
        color: var(--text-quaternary);
        letter-spacing: 0;

        &:not(:last-child)::after {
          content: "";
          background-color: var(--text-quaternary);
          width: 2px;
          height: 2px;
          position: absolute;
          right: -6px;
          top: 0;
          bottom: 0;
          margin: auto;
          border-radius: 50%;
        }
      }
    }
  }
}
