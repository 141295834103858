.navbar {

  @media (max-width: 1300px) {
    position: fixed;
    left: 0;
    top: 78px;
    height: 100%;
    width: 100%;
    background: var(--black);
    transition: opacity 300ms ease;
    opacity: 0;
    pointer-events: none;
  }

  .menu-list {
    display: flex;
    gap: 64px;

    @media (max-width: 1024px) {
      height: 100%;
    }

    .menu-item {

      @media (max-width: 1300px) {
        width: 100%;
      }

      &.has-sub-menu {
        position: relative;

        &:has(:focus-within),
        &:has(.sub-menu:hover) {
          > a {
            color: inherit;
            &::before {
              width: 100%;
            }
          }
        }

        &:hover {
          .sub-menu {
            pointer-events: all;
            opacity: 1;
          }
        }
      }

      &.is-active a {
        color: var(--white);
      }

      > a, > button {
        font-family: var(--font-montserrat);
        background-color: transparent;
        width: fit-content;
        height: 40px;
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        color: var(--text-secondary);
        text-transform: inherit;
        font-weight: 500;

        @media (max-width: 1300px) {
          width: 100%;
          justify-content: space-between;
          padding: 16px 20px;
          height: auto;
        }

        &:hover {
          color: inherit;
          &::before {
            width: 100%;
          }
        }

        &::before {
          content: "";
          width: 0px;
          height: 2px;
          background-color: var(--ow-red);
          position: absolute;
          bottom: 0;
          left: 0;
          transition: all 150ms ease;

          @media (max-width: 1300px) {
            display: none;
          }
        }

        svg {
          width: 24px;
          height: 24px;
          color: var(--icons-secondary);
          transition: all 150ms ease;
        }

        &.is-open {
          svg {
            transform: rotate(180deg);

            @media (min-width: 1024px) {
              transform: rotate(0);
            }
          }

          +.sub-menu {
            max-height: 1000px;
            @media (max-width: 1024px) {
              padding: 8px 0;
            }
          }
        }
      }

      .sub-menu {
        background-color: var(--surface-secondary);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        padding: 48px 56px 56px;
        position: absolute;
        top: 57px;
        left: 0;
        display: flex;
        opacity: 0;
        pointer-events: none;
        transition: all 150ms ease;

        @media (max-width: 1300px) {
          opacity: 1;
          width: 100%;
          padding: 0;
          gap: 0;
          overflow: hidden;
          max-height: 0;
        }

        @media (max-width: 1024px) {
          height: calc(100% - 216px);
          overflow-y: scroll;
          flex-direction: column;
        }

        a {
          justify-content: flex-start;
        }

        &:hover,
        &:focus-within {
          pointer-events: all;
          opacity: 1;
        }

        &::before {
          content: "";
          width: 100%;
          height: 30px;
          position: absolute;
          top: -20px;
          left: 0;
        }

        .sub-menu-item-list {
          position: relative;
          width: 280px;

          @media (max-width: 1024px) {
            width: 100%;

            &:not(:last-child) {
              margin-bottom: 16px;

              &::after {
                content: '';
                height: 1px;
                width: calc(100% - 36px);
                background-color: var(--surface-tertiary);
                position: absolute;
                bottom: -8px;
                left: 0;
                right: 0;
                margin: auto;
              }
            }
          }

          h2 {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 2.8px;
            text-transform: uppercase;
            padding: 12px 16px;
            color: var(--text-quaternary);
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 1024px) {
              padding: 12px 28px 12px 20px;
            }

            svg {
              width: 24px;
              height: 24px;
              color: var(--icons-secondary);
              transition: transform 150ms ease;

              @media (min-width: 1024px) {
                display: none;
              }
            }

            &.is-open {

              svg {
                transform: rotate(180deg);
              }

              + .menu-column-list  {
                max-height: 800px;
              }

            }
          }

          .menu-column-list {
            display: flex;
            flex-direction: column;

            @media (max-width: 1024px) {
              max-height: 0;
              overflow: hidden;
              transition: max-height 300ms ease;
            }

            .menu-item {

              a {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
                padding: 8px 16px;
                width: 100%;
                gap: 12px;

                @media (max-width: 1024px) {
                  padding: 8px 20px;
                }

                img {
                  width: 24px;
                  height: 24px;
                }

                &:hover {
                  color: var(--white);
                }

                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
