.reviews-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(22, 22, 22, 0.79);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  z-index: 101;
  padding: 20px;
  opacity: 1;
  visibility: visible;
  transition: all 300ms ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:not(.modal-is-open) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .close-modal {
    position: absolute;
    right: 8px;
    top: 8px;

    @media (max-width: 600px) {
      right: 0;
      top: 0;
    }
  }

  .reviews-modal-main {
    background-color: var(--surface-secondary);
    position: relative;
    width: 640px;
    height: 696px;
    margin: auto;

    @media (max-height: 720px){
      height: 640px;
    }

    @media (max-width: 800px){
      width: 100%;
    }

    @media (max-width: 600px) {
      height: 520px;
    }

    // &::after {
    //   content: '';
    //   width: calc(100% - 40px);
    //   height: 100px;
    //   pointer-events: none;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   z-index: 1;
    //   background: linear-gradient(180deg, rgb(33 33 33 / 0) 8.23%, var(--surface-secondary) 61.7%);

    //   @media (max-width: 600px){
    //     width: calc(100% - 20px);
    //   }
    // }

    .reviews-modal-header {
      background-color: var(--surface-tertiary);
      padding: 24px;
      display: flex;
      gap: 16px;
      align-items: flex-end;

      @media (max-width: 600px) {
        padding: 16px;
      }

      .app-thumb {
        width: 56px;

        @media (max-width: 600px) {
          width: 48px;
        }
      }

      .app-title {

        h2 {
          font-weight: 700;
          font-size: 24px;
          line-height: 116.667%;
          margin-bottom: 4px;

          @media (max-width: 600px) {
            font-size: 20px;
          }
        }

        p {
          font-family: var(--font-montserrat);
          font-size: 14px;
          font-weight: 500;
          line-height: 140%; 
          color: var(--text-quaternary);
        }
      }

    }

    .reviews-modal-content {
      padding: 8px 8px 0 24px;

      @media (max-width: 600px) {
        padding: 8px 8px 0 16px;
      }

      .clean-btn {
        font-size: 14px;
      }

      .reviews-modal-list {
        height: 580px;
        overscroll-behavior-y: contain;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 16px 12px 82px 0;

        @media (max-height: 720px){
          height: 520px;
        }

        @media (max-width: 600px){
          height: 430px;
        }

        // &:has(+ .load-more.is-active) {
        //   padding-bottom: 82px;
        // }

        .review-item {

          &:not(:last-of-type) {
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid var(--surface-tertiary);
          }

          .review-header {
            display: flex;
            flex-wrap: wrap;
            column-gap: 12px;
            margin-bottom: 16px;

            .user-avatar {
              width: 48px;

              img {
                border-radius: 50%;
              }

            }

            .user-info {
              flex: 1;

              .title {
                font-family: var(--font-lato);
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                margin-bottom: 4px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 8px;
              }

              .date {
                font-size: 12px;
                color: var(--text-quaternary);
              }

              .ratings {
                height: 0;
                font-size: 20px;
                flex-basis: 100%;
                position: relative;
                top: -5px;
              }
            }
          }

          .review-content {

            a {
              text-decoration: underline;
            }

            .user-review {
              font-size: 16px;
              line-height: 150%;
              color: var(--text-tertiary);

              &:empty {
                display: none;
              }

              + button {
                margin-top: 12px;
              }
            }

            .creator-response {
              background-color: var(--surface-tertiary);
              padding: 12px 16px 16px;
              margin-top: 16px;

              h3 {
                font-family: var(--font-lato);
                font-size: 16px;
                font-weight: 700;
                line-height: 150%;
                display: flex;
                gap: 10px;
                margin-bottom: 8px;

                span {
                  font-size: 14px;
                  background-color: var(--surface-tertiary);
                  padding: 4px 8px;
                  position: relative;
                  top: -3px;
                  display: none;
                }
              }

              .response-content {
                font-size: 16px;
                color: var(--text-tertiary);
                line-height: 150% ;

                + button {
                  margin-top: 12px;
                }
              }
            }

            .read-more {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              height: 78px;

              &.is-shown {
                display: block;
                overflow: visible;
                height: auto;
              }
            }

          }
        }
      }
    }

  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .load-more {
    position: absolute;
    z-index: 2;
    width: fit-content;
    right: 0;
    left: 0;
    bottom: 24px;
    margin: auto;
    opacity: 1;
    display: flex;
    justify-content: center;

    &:not(.is-active) {
      pointer-events: none;
      opacity: 0;
    }

    svg {
      position: absolute;
      width: 0px;
      height: 0px;
      margin: auto;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      opacity: 0;
      pointer-events: none;
      transition: all 300ms ease;
      animation: 0.75s linear rotating infinite;

      @keyframes rotating {
        to {
          transform: rotate(360deg);
        }
      }
    }

    &.loading {
      color: var(--surface-tertiary);
      font-size: 0;
      padding: 0;
      width: 40px;
      border-radius: 50%;

      svg {
        width: 20px;
        height: 20px;
        opacity: 1;
      }
    }
  }

}
