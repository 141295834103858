.app-share-modal {

  .share-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(22, 22, 22, 0.79);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    opacity: 1;
    visibility: visible;
    transition: all 300ms ease;

    &:not(.modal-is-open) {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .share-modal-main {
      background-color: var(--surface-secondary);
      position: relative;
      padding: 64px;
      width: 640px;

      @media (max-width: 800px) {
        padding: 24px;
        width: 100%;
      }

      .close-modal {
        position: absolute;
        right: 8px;
        top: 8px;
      }

      .asset {
        margin: auto;
      }

      h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 125%;
        text-align: center;
        margin: 24px 0 8px;
      }

      p {
        color: var(--text-tertiary);

        &:not(.or) {
          margin-bottom: 24px;
          text-align: center;
        }
  
      }

      .or {
        text-align: center;
        margin: 24px 0;
      }

      .share-social-list {
        display: flex;
        gap: 24px;
        justify-content: center;
      }
    }

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}
