.app-technical-details {
  margin: 40px 0 56px;
  position: relative;

  @media (max-width: 600px) {
    margin: 24px 0;
  }

  &.is-open {
    .content {
      background-size: 560px;

      @media (max-width: 600px) {
        background-size: 100%;
      }

      .expand-content {
        opacity: 1;
        padding-top: 40px;
        max-height: 680px;

        @media (max-width: 600px) {
          max-height: 1000px;
        }
      }
    }
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;

    @media (max-width: 600px) {
      margin-bottom: 16px;
      font-size: 20px;
    }
  }

  .content {
    background: linear-gradient(
        90deg,
        var(--surface-secondary) -8.7%,
        rgba(28, 28, 28, 0) 108.57%
      ),
      url("/static_next/img/app-page/client-bg.webp");
    background-color: var(--surface-secondary);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 242px;
    padding: 24px 24px 32px 68px;
    transition: background-size 300ms ease;

    @media (max-width: 600px) {
      padding: 40px 24px 24px 24px;
      background-size: 100%;
      background-image: url("/static_next/img/app-page/client-bg-mobile.webp");
    }

    .client-info {
      max-width: 754px;

      h3 {
        font-family: var(--font-lato);
        color: var(--text-secondary);
        font-weight: 700;
        font-size: 18px;
        line-height: 155.556%;
        margin-bottom: 16px;
        position: relative;

        @media (max-width: 600px) {
          margin-bottom: 8px;
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        svg {
          width: 32px;
          height: 32px;
          position: absolute;
          left: -44px;
          color: var(--white);

          @media (max-width: 600px) {
            position: unset;
          }
        }
      }

      p {
        color: var(--text-tertiary);
        margin-bottom: 24px;
        position: relative;
        line-height: 150%;
      }
    }

    .expand-content {
      opacity: 0;
      padding: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 300ms ease;

      h4 {
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 40px;
      }

      .info-list {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        margin-bottom: 40px;

        li {
          max-width: 300px;

          img {
            width: 80px;
            height: 80px;
          }

          h5 {
            font-family: var(--font-lato);
            font-weight: 700;
            font-size: 18px;
            line-height: 155.556%;
            margin: 24px 0 16px;

            @media (max-width: 600px) {
              margin-bottom: 8px;
            }
          }

          p {
            color: var(--text-tertiary);
          }
        }
      }
    }
  }
}
