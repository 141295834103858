button,
a.btn-primary,
a.btn-secondary {
  font-family: var(--font-montserrat);
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  transition: all 150ms ease;
  border: none;
  width: fit-content;

  svg {
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.btn-primary, &.btn-secondary {
    padding: 16px 32px;
    height: 48px;
  }

  &.btn-primary {
    background-color: var(--ow-red);
    color: var(--white);
    line-height: 110%;

    @media (any-hover: hover) {
      &:hover {
        background-color: var(--ow-red-hover);
      }
    }

    &:focus,
    &:active {
      background-color: var(--ow-red);
      color: var(--white);
    }
  }

  &.btn-secondary {
    background-color: transparent;
    color: var(--text-secondary);
    border: 1px solid var(--text-secondary);
    line-height: 100%;

    @media (any-hover: hover) {
      &:hover {
        background-color: var(--ow-red-hover);
        border-color: var(--ow-red-hover);
        color: var(--white);
      }
    }

    &:focus,
    &:active {
      background-color: transparent;
      color: var(--text-secondary);
      border: 1px solid var(--text-secondary);
    }
  }

  &.icon {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 110%;

    svg {
      width: 20px;
      height: 20px;
      transition: color 150ms ease;
    }
  }

  &.small {
    padding: 8px 32px;
    line-height: 160%;
    height: 40px;
    text-transform: inherit;
  }

}

button.clean-btn, a.clean-btn {
  background-color: transparent;
  color: var(--text-secondary);
  padding: 0;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: inherit;

  @media (any-hover: hover) {
    &:hover,
    &:hover svg {
      @media (min-width: 600px) {
        color: var(--ow-red);
      }
    }
  }

  &:focus,
  &:active,
  &:focus svg {
    color: var(--text-secondary);
  }

  svg {
    width: 20px;
    height: 20px;
    color: var(--icons-secondary);
    transition: color 150ms ease;
  }

  .hidden + svg {
    display: none;
  }
}

.clean-icon-btn {
  background-color: transparent;
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--icons-secondary);

  @media (any-hover: hover) {
    &:hover {
      color: var(--text-secondary);
      
      @media (min-width: 800px) {
        background-color: var(--core-color-alfa-dark-16);
      }
    }
  }

  &:focus,
  &:active {
    background-color: transparent;
    color: var(--icons-secondary);
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &.static {
    color: var(--text-secondary);
  }
}

.btn-secondary-small {
  font-family: var(--font-montserrat);
  text-decoration: none;
  font-weight: 500;
  padding: 8px 32px;
  font-size: 16px;
  line-height: 140%;
  height: 40px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  background-color: transparent;
  color: var(--text-secondary);
  border: 1px solid var(--text-secondary);
  text-transform: inherit;

  @media (any-hover: hover) {
    &:hover {

    @media (min-width: 600px) {
      background-color: var(--ow-red-hover);
      border-color: var(--ow-red-hover);
      color: var(--white);

      svg {
        color: var(--white);
      }
    }
    
    }
  }

  &:focus,
  &:active {
    background-color: transparent;
    color: var(--text-secondary);
    border: 1px solid var(--text-secondary);

    svg {
      color: var(--text-secondary);
    }
  }
}

.btn-icon-left {
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    position: relative;
    bottom: -1px;
    color: var(--icons-secondary);
    // transition: color 150ms ease;
  }
}

.icon-btn {
  display: block;
  background-color: var(--core-color-alfa-dark-16);
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--icons-secondary);

  @media (any-hover: hover) {
    &:hover,
    &:focus {
      color: var(--text-secondary);
      background-color: var(--core-color-alfa-dark-64);
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.tool-tip {
  position: absolute;
  z-index: 110;
  background-color: var(--surface-quaternary);
  color: var(--text-secondary);
  padding: 16px;
  font-family: var(--font-montserrat);
  font-size: 14px;
  line-height: 20px;
  min-height: 44px;
  max-width: 400px;
  width: fit-content;
  opacity: 0;
  --pointer-offset-x: -4px;
  --pointer-offset-y: -4px;

  &::before {
    content: "";
    position: absolute;
    margin: auto;
    background-color: var(--surface-quaternary);
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
  }

  &.top {
    animation: fadeinSlideFromTop 150ms linear forwards;
    animation-delay: 150ms;

    &::before {
      left: 50%; // centered
      margin-left: var(--pointer-offset-x); // offset of triangle pointer
      bottom: -4px;
    }
  }

  &.bottom {
    animation: fadeinSlideFromBottom 150ms linear forwards;
    animation-delay: 150ms;

    &::before {
      left: 50%; // centered
      margin-left: var(--pointer-offset-x); // offset of triangle pointer
      top: -4px;
    }
  }

  &.right {
    animation: fadeinSlideFromLeft 150ms linear forwards;
    animation-delay: 150ms;

    &::before {
      left: -4px;
      top: 50%;
      margin-top: var(--pointer-offset-y); // offset of triangle pointer
    }
  }

  &.left {
    animation: fadeinSlideFromRight 150ms linear forwards;
    animation-delay: 150ms;

    &::before {
      right: -4px;
      top: 50%;
      margin-top: var(--pointer-offset-y); // offset of triangle pointer
    }
  }

  @keyframes fadeinSlideFromLeft {
    0% {
      transform: translateX(-30px);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  @keyframes fadeinSlideFromRight {
    0% {
      transform: translateX(30px);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  @keyframes fadeinSlideFromTop {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeinSlideFromBottom {
    0% {
      transform: translateY(-14px);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(10px);
    }
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="search"],
textarea {
  border: 1px solid var(--surface-quaternary);
  color: var(--text-secondary);
  background-color: transparent;
  height: 48px;
  width: 100%;
  padding: 0 12px;
  font-size: 16px;
  outline: none;
  caret-color: var(--ow-red);

  &::placeholder {
    color: var(--text-quaternary);
  }

  &:hover,
  &:focus {
    border-color: var(--text-secondary);
    outline: none;
  }

  &:disabled,
  &:read-only,
  &.disabled {
    color: var(--surface-quaternary);
    pointer-events: none;
  }
}

input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: black;
  border: 1px solid #999999;

  &:hover,
  &:focus {
    border: 1px solid #cccccc;
    background: rgba(255, 255, 255, 0.16);
  }

  &:checked {
    border: 1px solid #f3513e;
    background: #f3513e;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2220%22%20height%3D%2221%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0D%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M15.6%205.55501C16.0418%205.90007%2016.1314%206.55277%2015.8%207.01286L9.8%2015.3434C9.62607%2015.5849%209.36005%2015.7359%209.07089%2015.7573C8.78173%2015.7787%208.49788%2015.6684%208.29289%2015.455L5.29289%2012.331C4.90237%2011.9243%204.90237%2011.265%205.29289%2010.8583C5.68342%2010.4517%206.31658%2010.4517%206.70711%2010.8583L8.89181%2013.1333L14.2%205.76327C14.5314%205.30318%2015.1582%205.20994%2015.6%205.55501Z%22%20fill%3D%22%23F4F2FF%22/%3E%0D%0A%3C/svg%3E%0D%0A");

    &:hover {
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2220%22%20height%3D%2221%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0D%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M15.6%205.55501C16.0418%205.90007%2016.1314%206.55277%2015.8%207.01286L9.8%2015.3434C9.62607%2015.5849%209.36005%2015.7359%209.07089%2015.7573C8.78173%2015.7787%208.49788%2015.6684%208.29289%2015.455L5.29289%2012.331C4.90237%2011.9243%204.90237%2011.265%205.29289%2010.8583C5.68342%2010.4517%206.31658%2010.4517%206.70711%2010.8583L8.89181%2013.1333L14.2%205.76327C14.5314%205.30318%2015.1582%205.20994%2015.6%205.55501Z%22%20fill%3D%22%23F4F2FF%22/%3E%0D%0A%3C/svg%3E%0D%0A");
    }
  }
}

textarea {
  min-height: 137px;
  padding-top: 10px;
  resize: vertical;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out" !important;
  -webkit-transition-delay: 9999s !important;
  -webkit-box-shadow: 0 0 0 40px white inset !important;
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  transition: opacity 0.4s, transform 0.4s ease-in-out;
  transform: scale(0.4);
  opacity: 0;

  svg {
    width: 18px;
    height: 18px;

    @keyframes rotating {
      to {
        transform: rotate(360deg);
      }
    }
  }
}

.is-loading {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 5px solid var(--white);
    border-top-color: transparent;
    animation: rotating 2s linear infinite;
    z-index: 1;
  }
}
