.download-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(22, 22, 22, 0.79);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  opacity: 1;
  visibility: visible;
  transition: all 300ms ease;

  &:not(.modal-is-open) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .anim-icon {
    width: 100px;
    height: 100px;
    margin: 0 auto 32px;
  }

  .download-modal-main {
    background-color: var(--surface-secondary);
    position: relative;
    padding: 64px;
    width: 723px;

    @media (max-width: 800px){
      width: 100%;
      height: auto;
    }

    .download-modal-content {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 48px;
      height: 100%;

      .dl-img {
        margin: auto;
        margin-bottom: 12px;
      }

      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        text-align: center;
        margin-bottom: 8px;
      }

      p {
        color: var(--text-tertiary);
      }

      .small {
        font-size: 14px;
        font-weight: 400;
        line-height: 142.857%; 
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

    }

    .close-modal {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }

}
