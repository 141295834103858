.app-about-content {
  margin: 16px 0 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;

  @media (max-width: 600px) {
    margin: 24px 0 40px;
  }

  .about-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 116.667%;
    margin-bottom: 24px;

    @media (max-width: 600px) {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 120%;
    }
  }

  .app-about-content-inner {
    &:has(+ .expand-btn) {
      padding-bottom: 32px;

      @media (max-width: 600px) {
        padding-bottom: 16px;
      }
    }

    &:not(.expand-container) {
      .expand-btn {
        display: none;
      }
    }

    &.expand-container {
      max-height: 370px;
      overflow: hidden;
      transition: max-Height 300ms ease;

      @media (max-width: 600px) {
        max-height: 440px;
      }
    }

    .main-content {
      @media (max-width: 800px) {
        margin-bottom: 16px;
      }

      .app-info {
        max-width: 828px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          &:not(.tag-group-title) {
            font-weight: 700;
            font-size: 18px;
            font-family: var(--font-lato);
            line-height: 155.556%;
            margin: 24px 0 8px;

            &:first-child {
              margin-top: 0;
            }
          }

          // @media (max-width: 600px) {
          //   font-size: 16px;
          //   line-height: 24px;
          //   margin-bottom: 20px;
          //   margin-top: 32px;

          // }
        }

        > ul {
          list-style: none;

          li {
            position: relative;
            &:before {
              content: "";
              position: absolute;
              left: -29px;
              top: 1px;
              width: 24px;
              height: 24px;
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 11.9991C13.3337 11.9991 12 10.6661 12 -0.000915527C12 10.6661 10.6663 11.9991 0 11.9991C10.6663 11.9991 12 13.3321 12 23.9991C12 13.3321 13.3337 11.9991 24 11.9991Z' fill='%23D34037'/%3E%3C/svg%3E");
              background-position: center center;
            }
          }
        }

        > ul,
        > ol {
          padding-left: 25px;
          margin-bottom: 24px;
          color: var(--text-tertiary);

          @media (max-width: 600px) {
            margin-top: 12px;
          }

          li {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            &:not(:last-child) {
              margin-bottom: 8px;
            }

            &:has(input[type=checkbox]) {
              &::before {
                display: none;
              }
            }
          }
        }

        input[type=checkbox] {
          appearance: none;
          width: 20px;
          height: 20px;
          background-color: transparent;
          border: 1px solid var(--surface-tertiary-);
          background: var(--ow-red);
          background-position: center center;
          background-repeat: no-repeat;
          background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2220%22%20height%3D%2221%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0D%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M15.6%205.55501C16.0418%205.90007%2016.1314%206.55277%2015.8%207.01286L9.8%2015.3434C9.62607%2015.5849%209.36005%2015.7359%209.07089%2015.7573C8.78173%2015.7787%208.49788%2015.6684%208.29289%2015.455L5.29289%2012.331C4.90237%2011.9243%204.90237%2011.265%205.29289%2010.8583C5.68342%2010.4517%206.31658%2010.4517%206.70711%2010.8583L8.89181%2013.1333L14.2%205.76327C14.5314%205.30318%2015.1582%205.20994%2015.6%205.55501Z%22%20fill%3D%22%23F4F2FF%22/%3E%0D%0A%3C/svg%3E%0D%0A');
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
          color: var(--text-tertiary);

          &:last-child {
            margin-bottom: 0;
          }
        }

        b,
        strong {
          color: var(--text-secondary);
        }
      }
    }
  }

  .expand-btn {
    width: 100%;
    position: relative;
    box-shadow: -45px -29px 24px var(--black);

    @media (max-width: 600px) {
      box-shadow: 0px -23px 13px var(--black);
      height: 40px;
      line-height: 14px;
    }

    &.show {
      opacity: 1;
    }
  }

  //just for light version
  + .app-inventory-section {
    margin-top: 24px;
  }
}
