.app-inventory-section {
  margin: 40px 0;

  @media (max-width: 600px) {
    margin: 24px 0;
  }

  h2 {
    font-family: var(--surface-lato);
    font-weight: 700;
    font-size: 18px;
    line-height: 155.556%;
    margin-bottom: 16px;
  }

  .app-inventory-list {
    display: flex;
    gap: 16px 24px;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .app-inventory-item {
      background-color: var(--surface-secondary);
      padding: 16px 40px 24px;
      flex: 1;
      display: flex;
      align-items: center;
      gap: 8px 40px;

      &:only-child {
        justify-content: center;
      }

      @media (max-width: 960px) {
        padding: 16px 24px 24px;
      }

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .asset {
        flex: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4px 8px;
        font-family: var(--font-montserrat);
        font-size: 14px;
        font-weight: 700;
        line-height: 142.857%;
        color: var(--ow-red);

        img {
          margin: auto;
          width: 52px;
          // @media (max-width: 600px) {
          //   width: 32px;
          // }
        }

      }

      p {
        line-height: 150%;
        max-width: 340px;
        color: var(--text-tertiary);

        span {
          font-weight: 700; 
          color: var(--text-secondary );
          display: block;
          margin-top: 4px;
        }
      }
    }
  }
}
