.remind-me-window-section {
  position: fixed;
  z-index: 10;
  background-color: var(--surface-tertiary);
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  padding: 16px;
  display: none;
  visibility: hidden;

  @media (max-width: 1024px) {
    display: block;
    visibility: visible;
  }

  .get-the-app-area {
    display: flex;
    align-items: center;

    button {
      width: 113px;
      height: 40px;
      padding: 0 12px;
      font-size: 14px;
      line-height: 16px;
      margin-left: auto;
      font-weight: 600;
    }

    a {
      width: 113px;
      height: 40px;
      padding: 12px 0px 0 25px;
      font-size: 14px;
      line-height: 16px;
      margin-left: auto;
      font-weight: 600;
    }

    .app-info {
      display: flex;
      align-items: center;
      gap: 12px;

      .app-thumb {
        width: 40px;
      }

      .app-title {
        h6 {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .remind-me-window {
    padding: 48px 16px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -460px;
    background-color: var(--surface-tertiary);
    text-align: center;
    transition: all 300ms ease;
    opacity: 0;

    &:not(.is-active) {
      pointer-events: none;
    }

    &.is-active {
      bottom: 0;
      opacity: 1;

      + .get-the-app-area {
        opacity: 0;
        pointer-events: none;
      }
    }

    form {
      opacity: 0;
      transition: opacity 150ms ease;

      &.is-shown {
        opacity: 1;
      }
    }

    .asset {
      margin: 0 auto 16px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      color: var(--text-secondary);

      &.validation-form {
        color: var(--ow-red);
        margin: 12px;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .input-field {
      padding: 24px 0 16px;
      position: relative;
    }

    .close-window {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 3;
    }

    .remind-me {
      position: relative;
      margin: auto;
      width: 100%;

      svg {
        position: absolute;
        width: 0px;
        height: 0px;
        margin: auto;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        pointer-events: none;
        transition: all 300ms ease;
        animation: 0.75s linear rotating infinite;

        @keyframes rotating {
          to {
            transform: rotate(360deg);
          }
        }
      }

      &.loading {
        width: 50px;
        font-size: 0;
        padding: 0;
        border-radius: 50%;

        svg {
          width: 20px;
          height: 20px;
          opacity: 1;
        }
      }
    }

    .success-state {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 12px;
      opacity: 0;
      transition: opacity 150ms ease;
      pointer-events: none;

      .success-state-inner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 54px;
        margin: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        transform: rotateZ(180deg);
        transition: 0.5s,
          bottom 0.5s 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0s,
          transform 375ms;

        p {
          opacity: 0;
          transition: 0.2s 0.8s;
        }

        svg {
          width: 30px;
          height: 27px;
        }
      }

      &.is-shown {
        opacity: 1;

        .success-state-inner {
          transform: rotateZ(360deg);
          bottom: 174px;

          p {
            opacity: 1;
          }
        }
      }
    }
  }

  .tooltip-alert {
    background: var(--ow-red);
    color: var(--white);
    width: max-content;
    position: absolute;
    font-size: 16px;
    line-height: 20px;
    z-index: 8;
    opacity: 0;
    top: 0px;
    padding: 8px 16px;
    pointer-events: none;
    transition: all 150ms;
    max-width: 290px;
    height: fit-content;
    pointer-events: none;

    @media (max-width: 800px) {
      min-width: 100%;
      text-align: center;
    }

    &.active {
      opacity: 1;
      top: -16px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px;
      margin: auto;
      left: 22px;
      transform: rotate(-270deg);
      opacity: 1;
      transition: all 150ms;

      @media (max-width: 760px) {
        left: 0;
        right: 0;
      }
    }

    &::before {
      border-color: transparent transparent transparent var(--ow-red);
      bottom: -16px;
    }

    &::after {
      border-color: transparent transparent transparent var(--ow-red);
      bottom: -15px;
    }
  }
}
