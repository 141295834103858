.copy-url {
  border: 1px solid var(--border-tertiary);
  padding: 3px 12px;
  height: 48px;
  display: flex;
  gap: 8px;
  width: 100%;

  input {
    font-family: var(--font-montserrat);
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-tertiary);

    &:focus {
      outline: none;
    }
  }

  button {
    position: relative;
    text-transform: inherit;
    padding-right: 0;

    &:hover {
      background-color: transparent;
      .copied-notice {
        opacity: 1;
      }
    }
  }

  .copied-notice {
    position: absolute;
    background-color: var(--surface-quaternary);
    color: var(--text-secondary);
    white-space: nowrap;
    padding: 12px;
    font-family: var(--font-montserrat);
    font-size: 14px;
    line-height: 140%;
    min-height: 44px;
    transition: opacity 300ms ease;
    opacity: 0;
    top: -42px;

    &::before {
      content: "";
      position: absolute;
      margin: auto;
      background-color: var(--surface-quaternary);
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      right: 0;
      left: 0;
      bottom: -4px;
    }
  }
}
