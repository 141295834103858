.progress-ring {
  --progress: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media (max-width: 600px) {
    flex-direction: row;
    align-items: flex-start;
  }

  .rating-type {
    font-family: var(--font-montserrat);
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 2px;
    position: absolute;
    top: 25px;
    left: 17px;

    @media (max-width: 600px) {
      flex-direction: row;
      position: unset;
      width: 36px;
      flex: none;
      justify-content: flex-end;
    }

    svg {
      width: 20px;
      height: 20px;
      color: var(--ow-red);
    }
  }

  .progress-percentage {
    font-family: var(--font-montserrat);
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: var(--text-quaternary);

    @media (max-width: 600px) {
      width: 48px;
      flex: none;
      text-align: left;
    }
  }

  .progress-bar {
    appearance: none;
    height: 8px;
    width: 100%;
    margin-top: 8px;
    border-radius: none;

    &::-webkit-progress-bar {
      background-color: var(--surface-tertiary);
    }

    &::-webkit-progress-value {
      background-color: var(--ow-red);
    }

    @media (min-width: 600px) {
      display: none;
    }
  }

  svg.ring {
    width: 72px;
    height: 72px;

    @media (max-width: 600px) {
      width: 45px;
      height: 45px;
      display: none;
    }
  }

  circle.progress-bg {
    stroke-width: 7px;
  }

  circle.progress {
    stroke-width: 7px;
    fill: none;
    paint-order: stroke;
    stroke-dasharray: 201px;
    stroke-dashoffset: calc(201px - 201px * (var(--progress) / 100));
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
}
