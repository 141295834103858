.reviews-slider-section {
  margin: 24px 0 40px;

  @media (max-width: 600px) {
    margin: 16px -20px 24px 0;
  }

  .reviews-slider-wrapper {
    position: relative;

    .reviews-slider-next, .reviews-slider-prev {
      width: 40px;
      height: 40px;
      padding: 4px;
      background-color: var(--ow-red);
      color: var(--white);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 10;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: opacity 300ms ease;

      @media (max-width: 600px) {
        display: none;
      }

      &:hover {
        background-color: var(--ow-red-hover);
      }

      &::after {
        display: none;
      }

      &:disabled {
        opacity: 0;
        pointer-events: all;
        cursor: auto;
      }
    }

    &:hover {
      .reviews-slider-next, .reviews-slider-prev {
        opacity: 1;

        &:disabled {
          opacity: 0.4;
        }
      }
    }


    .reviews-slider-next {
      right: -20px;
      transform: rotate(180deg)
    }

    .reviews-slider-prev {
      left: -20px;
    }
  }

  .reviews-list {

    li {
      background-color: var(--surface-secondary);
      padding: 16px 24px 24px 24px;
      min-height: 274px;
      width: 32%;
      height: auto;

      @media (max-width: 800px) {
        width: 49%;
      }

      @media (max-width: 600px) {
        padding: 16px 24px 24px ;
        width: calc(100vw - 70px);
      }

      .review {

        .review-header {
          margin-bottom: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 24px;

          .title {
            color: var(--text-secondary);
            font-family: var(--font-lato);
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
          }

          .date {
            font-family: var(--font-montserrat);
            font-weight: 500;
            font-size: 12px;
            line-height: 133.333%;
            color: var(--text-quaternary);
          }

        }

        .ratings {
          display: flex;
          margin-bottom: 16px;
        }

        .review-content {
          color: var(--text-tertiary);
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          font-weight: 400;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          box-orient: vertical;
          -webkit-line-clamp: 8;
          line-clamp: 8;
          overflow: hidden;
        }
      }

    }
  }
}
