.site-footer {
  font-family: var(--font-montserrat);
  width: 100%;
  padding-top: 30px;
  background-image: url("/static_next/img/footer-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  @media (max-width: 800px) {
    background-size: auto;
    padding-top: 16px;
  }

  .back-to-top {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    margin: auto;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-secondary);
    text-decoration: none;

    svg {
      color: var(--icons-secondary);
      width: 24px;
      height: 24px;
      transform: rotate(180deg);
      margin-bottom: 8px;
    }

    &:hover,
    &:hover svg {
      color: var(--ow-red-hover);
    }
  }

  .ow-shield {
    width: fit-content;
    display: block;
    height: auto;
    margin: 32px auto 224px auto;

    @media (max-width: 800px) {
      margin-bottom: 85px;
      margin-top: 104px;
      width: 210px;
    }
  }

  .site-footer-nav {
    max-width: 1660px;
    margin: 64px auto;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-direction: column;
      margin: 54px 0;
    }

    .ow-symbol {
      width: 90px;
      height: 90px;
      color: var(--white);
      margin-right: 10%;

      @media (max-width: 800px) {
        display: none;
      }
    }

    .ow-logo {
      display: none;
      color: var(--white);
      width: 160px;
      height: 50px;
      margin: 0 auto 100px;

      @media (max-width: 800px) {
        display: block;
      }
    }

    nav {
      flex: 1;

      & > ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        gap: 40px 10%;
        margin: 0;
        padding: 0;
        list-style: none;

        @media (max-width: 1680px) {
          column-gap: 8%;
        }

        @media (max-width: 1580px) {
          column-gap: 6%;
        }

        @media (max-width: 600px) {
          gap: 40px 5%;
        }

        h5 {
          font-size: 16px;
          line-height: 110%;
          color: var(--text-quaternary);
          font-weight: 700;
        }

        & ul {
          margin: 16px 0 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 16px;

          li {
            a {
              font-size: 14px;
              line-height: 110%;
              color: var(--text-quaternary);
              font-weight: 500;
              font-variation-settings: "wght" 500;
              text-decoration: none;
              white-space: nowrap;

              &:hover {
                color: var(--text-secondary);
                font-weight: 700;
                font-variation-settings: "wght" 700;
              }

              &.is-active {
                color: var(--white);
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }

  .site-footer-social {
    background-color: var(--surface-primary);

    .site-footer-social-inner {
      max-width: 1810px;
      margin: 0 auto;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;

      @media (max-width: 800px) {
        padding-top: 16px;
        padding-bottom: 16px;
        flex-direction: column;
      }
    }

    .copyright {
      color: var(--text-quaternary);
      font-size: 14px;
      line-height: 110%;
    }

    .social-links {
      display: flex;
      padding: 0;
      list-style: none;
      gap: 20px;

      li {
        height: 24px;

        a {
          width: 24px;
          height: 24px;
          display: inline-block;
          color: var(--text-quaternary);

          &:hover {
            color: var(--white);
          }
        }
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
