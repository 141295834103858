.app-background {
  min-height: 570px;
  position: relative;
  overflow: hidden;
  background-position: top center;
  background-size: cover;
  transition: min-height 150ms ease;

  @media (max-width: 1640px) {
    min-height: 480px;
    // background-size: contain;
  }

  @media (max-width: 1024px) {
    min-height: 380px;
  }

  @media (max-width: 800px) {
    min-height: 360px;
  }

  .app-bg {
    width: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 9%, 0) 24%,
      var(--black) 56%
    );

    @media (max-width: 600px) {
      background: linear-gradient(
        180deg,
        hsla(0, 0%, 9%, 0) 20%,
        var(--black) 38%
      );
    }
  }
}
